import React, { useEffect, useState } from 'react';
import './Expenses.css'
import Search from "./Search/Search";
import profileIcon from "../../../assets/img/profile-icon.svg";
import editIcon from "../../../assets/img/edit.png"
import tgIcon from "../../../assets/img/Telegram Icon White.png";
import Select from "react-select/base";
import CustomSelect from "./CustomSelect/CustomSelect";
import MoneyFlowService from '../../../services/MoneyFlowService';
import Pagination from '../Pagination/Pagination';
import { periodDecrypt } from '../../../helpers/periodDecrypt';
import Popup from '../../Popup/Popup';
import AddSpendingForm from './AddSpendingForm/AddSpendingForm';
const Expenses = () => {
    const [isAddSpendingFormOpen, setIsAddSpendingFormOpen] = useState<boolean>(false);
    const [artistValue, setArtistValue] = useState<string>("")
    const [releaseValue, setReleaseValue] = useState<string>("")
    const [categoryValue, setCategoryValue] = useState<string>("")
    const [articleValue, setArticleValue] = useState<string>("")
    const [isStockedValue, setIsStockedValue] = useState<boolean>(false)
    const [reportValue, setReportValue] = useState<string>("")
    const [moneyFlowItems, setMoneyFlowItems] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("")
    const itemsPerPage: number = 50;
    const recordType: string = "debit";
    const debitType: string = "expense";
    const options = [
        {
            value: "artist",
            label: "artist"
        },
        {
            value: "artist1",
            label: "artist1"
        },
        {
            value: "artist2",
            label: "artist2"
        },
        {
            value: "artist3",
            label: "artist3"
        },
        {
            value: "artist4",
            label: "artist4"
        }
    ]
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const onChange = (optionChangeFunction: Function, value: string) => {
        optionChangeFunction(value)
    }

    const [triggerCounter, setTriggerCounter] = useState(0);

    const handleExternalEvent = () => {
        // increment triggerCounter to trigger the effect to run again
        setTriggerCounter(triggerCounter + 1);
    }

    useEffect(() => {
        MoneyFlowService.getMoneyFlowItems(currentPage, itemsPerPage, recordType, debitType, searchText).then(res => {
            setMoneyFlowItems(res.money_flow_items);
            setTotalItems(res.money_flow_items_total_count);
            console.log(res)
        })
    }, [currentPage, triggerCounter])

    useEffect(() => {
        const timer = setTimeout(() => {
            MoneyFlowService.getMoneyFlowItems(currentPage, itemsPerPage, recordType, debitType, searchText).then(res => {
                setMoneyFlowItems(res.money_flow_items);
                setTotalItems(res.money_flow_items_total_count);
                console.log(res)
            })
        }, 400);
        return () => clearTimeout(timer);
    }, [searchText])

    return (
        <div>
            <div className={"expenses-top"}>
                <Search searchTextSetter={setSearchText}/>
                <div className={"expenses-controls"}>
                    <button className={"add-expense__button"} onClick={() => setIsAddSpendingFormOpen(true)}>
                        <span>Добавить расход</span>
                    </button>
                    {/* <div className={"sum-expenses"}><span>Итого: 0 ₽</span></div> */}
                </div>
            </div>
            <Popup isActive={isAddSpendingFormOpen} setActive={setIsAddSpendingFormOpen} >
                <AddSpendingForm setIsActive={setIsAddSpendingFormOpen} handleExternalEvent={handleExternalEvent}/>
            </Popup>
            <div className={"expenses__dashboard"}>
                <div className={"pagination__wrapper"}>
                    <Pagination currentPage={currentPage} paginate={paginate} itemsPerPage={itemsPerPage} totalItems={totalItems} />
                </div>
                <table className={"expenses__dashboard-table"}>
                    <thead className={"expenses-table__head"}>
                        <tr>
                            <th className={"padding-left"}></th>
                            <th className={"expenses-table__head-item artist"}>
                                <div className={"head-item__container"}>
                                    {/*<CustomSelect classNamePrefix={"custom-select"} placeholder={"Артист"} options={options} value={articleValue} onChange={onChange} />*/}
                                    <select className={"head-item__select"}>
                                        <option disabled selected hidden value={0}>Артист</option>
                                        <option value={"value1"}>value1</option>
                                        <option value={"value2"}>value2</option>
                                        <option value={"value3"}>value3</option>
                                        <option value={"value4"}>value4</option>
                                        <option value={"value5"}>value5</option>
                                    </select>
                                </div>
                            </th>
                            <th className={"expenses-table__head-item release_name"}>
                                <div className={"head-item__container"}>
                                    <select className={"head-item__select"}>
                                        <option disabled selected hidden value={0}>Название релиза</option>
                                        <option value={"value1"}>value1</option>
                                        <option value={"value2"}>value2</option>
                                        <option value={"value3"}>value3</option>
                                        <option value={"value4"}>value4</option>
                                        <option value={"value5"}>value5</option>
                                    </select>
                                </div>
                            </th>
                            <th className={"expenses-table__head-item category"}>
                                <div className={"head-item__container"}>
                                    <select className={"head-item__select"}>
                                        <option disabled selected hidden value={0}>Категория</option>
                                        <option value={"value1"}>value1</option>
                                        <option value={"value2"}>value2</option>
                                        <option value={"value3"}>value3</option>
                                        <option value={"value4"}>value4</option>
                                        <option value={"value5"}>value5</option>
                                    </select>
                                </div>
                            </th>
                            <th className={"expenses-table__head-item article"}>
                                <div className={"head-item__container"}>
                                    <select className={"head-item__select"}>
                                        <option disabled selected hidden value={0}>Статья</option>
                                        <option value={"value1"}>value1</option>
                                        <option value={"value2"}>value2</option>
                                        <option value={"value3"}>value3</option>
                                        <option value={"value4"}>value4</option>
                                        <option value={"value5"}>value5</option>
                                    </select>
                                </div>
                            </th>
                            <th className={"expenses-table__head-item sum"}>Сумма</th>
                            <th className={"expenses-table__head-item operation_date"}>Дата</th>
                            <th className={"expenses-table__head-item isStocked"}>
                                <div className={"head-item__container"}>
                                    <select className={"head-item__select"}>
                                        <option disabled selected hidden value={0}>Учтено</option>
                                        <option value={"value1"}>value1</option>
                                        <option value={"value2"}>value2</option>
                                        <option value={"value3"}>value3</option>
                                        <option value={"value4"}>value4</option>
                                        <option value={"value5"}>value5</option>
                                    </select>
                                </div>
                            </th>
                            <th className={"expenses-table__head-item report"}>
                                <div className={"head-item__container"}>
                                    <select className={"head-item__select"}>
                                        <option disabled selected hidden value={0}>Отчёт</option>
                                        <option value={"value1"}>value1</option>
                                        <option value={"value2"}>value2</option>
                                        <option value={"value3"}>value3</option>
                                        <option value={"value4"}>value4</option>
                                        <option value={"value5"}>value5</option>
                                    </select>
                                </div>
                            </th>
                            <th className={"expenses-table__head-item edit"}>
                            </th>
                            <th className={"padding-right"}></th>
                        </tr>
                    </thead>
                    <tbody className={"expenses-table__body"}>
                        {moneyFlowItems.map((item: any, key: number) => {
                            return <tr key={key}>
                                <td className={"padding-left"}></td>
                                <td className={"expenses-item__item artist"}>{item["artist"]}</td>
                                <td className={"expenses-item__item release_name"}>
                                    {item["album"]}
                                </td>
                                <td className={"expenses-item__item category"}>{item["category"]}</td>
                                <td className={"expenses-item__item article"}>{item["description"]}</td>
                                <td className={"expenses-item__item sum"}>{item["amount"]}</td>
                                <td className={"expenses-item__item operation_date"}>
                                    {item["date_added"]}
                                </td>
                                <td className={"expenses-item__item isStocked"}>
                                    {item["is_taken_into_account_in_the_report"] ?
                                        <div className={"isPaid paid expenses-item__flag"}>
                                            <span>Да</span>
                                        </div> :
                                        <div className={"isPaid unpaid expenses-item__flag"}>
                                            <span>Нет</span>
                                        </div>
                                    }
                                </td>
                                <td className={"expenses-item__item report"}>{periodDecrypt(item["period"])}</td>
                                <td className={"expenses-item__item edit"}><img src={editIcon} alt="" /></td>
                                <td className={"padding-right"}></td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <div className={"pagination__wrapper"}>
                    <Pagination currentPage={currentPage} paginate={paginate} itemsPerPage={itemsPerPage} totalItems={totalItems} />
                </div>
            </div>
        </div>
    );
};

export default Expenses;